import request from '@/plugins/request'
export function save (data) {
  return request.request({
    url: 'service/saveRenew',
    method: 'POST',
    data
  })
}

export function getType (data) {
  return request.request({
    url: 'service/getType',
    method: 'POST',
    data
  })
}

export function saveProtect (data) {
  return request.request({
    url: 'service/saveProtect',
    method: 'POST',
    data
  })
}

export function checkWarrantyStatus (data) {
  return request.request({
    url: 'service/checkWarrantyStatus',
    method: 'POST',
    data
  })
}
export function checkWickStatus (data) {
  return request.request({
    url: 'service/checkWickStatus',
    method: 'POST',
    data
  })
}
