<template>
  <div class="content">
    <div style="position: fixed;top: 20px;right: 50px">
      <div style="color: #fff;cursor: pointer" @click="loginOut()" v-if="token!==''">退出登陆</div>
    </div>
    <div class="form_list search_form" v-if="token===''">
      <form style="width:90%">
        <div class="item">
          <Input type="text" v-model="formInline.account" placeholder="请输入经销商账号" maxlength="20"/>
        </div>
        <div class="item">
          <Input type="password" v-model="formInline.password" placeholder="请输入密码" maxlength="11"/>
        </div>
        <div class="btns">
          <Button class="btn-box" :loading="loading" @click="loginBtn()">登 陆</Button>
          <Button class="btn-box" @click="resetData()">重 置</Button>
        </div>
      </form>
    </div>
    <div class="form_list zc_form" style="min-width: 600px;" v-else>
      <!--            <div class="cancel">退出登陆</div>-->
      <form>
        <div class="item">
          <span class="label">所属机构</span>
          <div class="desc">
            <span>{{ name }}</span>
          </div>
        </div>
        <div class="item">
          <span class="label">你的姓名</span>
          <Input type="text" v-model="info.account" placeholder="请输入姓名"/>
        </div>
        <div class="item">
          <span class="label">手机号码</span>
          <Input type="text" v-model="info.tel" placeholder="请输入手机号码"/>
        </div>
        <div class="item">
          <span class="label">车牌或车架号</span>
          <Input type="text" v-model="info.frame" placeholder="请输入车牌或车架号"/>
        </div>
        <div class="item">
          <span class="label">质保单号</span>
          <Input type="text" v-model="info.warranty" placeholder="请输入质保单号" @on-blur="checkWarranty"/>
        </div>
        <div class="item">
          <span class="label">卷心号</span>
          <Input type="text" v-model="info.wick" placeholder="请输入卷心号" @on-blur="checkWick"/>
        </div>

        <div class="item">
          <span class="label">施工类型</span>
          <Select v-model="typeSelectindex" style="width:360px" :disabled="checkWarrantyStatus">
            <Option v-for="(item,index) in type" :value="index" :key="item.value">{{ item.label }}</Option>
          </Select>
        </div>
        <div class="item" v-if="model">
          <span class="label">产品型号</span>
          <Select v-model="modelSelectindex" style="width:360px" :disabled="checkWarrantyStatus">
            <Option v-for="(item,index) in model" :value="index" :key="item.value">{{ item.label }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="label">施工日期</span>
          <DatePicker type="date" v-model="time"   :options="options" placeholder="请选择施工日期" style="width: 360px"></DatePicker>
        </div>
        <div class="item last-item" v-if="model" :key="typeSelectindex">
          <span class="label">施工部位</span>
          <CheckboxGroup v-if="type[typeSelectindex]" v-model="partList" on-change="change">
            <Checkbox :label="item.value" v-for="(item,index) in part[type[typeSelectindex].value]" :key="index">
              <span class="choice"> {{ item.label }}</span>
            </Checkbox>
          </CheckboxGroup>
        </div>
        <div class="btns">
          <Button class="btn-box" @click="save()" :loading="loading">提 交</Button>
          <Button class="btn-box">重 置</Button>
        </div>
      </form>
    </div>
    <div class="result-box" v-if="false">
      <span>提交成功，等待审核！</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { login } from '@/api/warranty'
import {
  getType,
  saveProtect,
  checkWickStatus,
  checkWarrantyStatus
} from '@/api/service.js'

var moment = require('moment')
export default {
  data () {
    return {
      options: {
        disabledDate (date) {
          return date && date.valueOf() > Date.now()
        }
      },
      loading: false,
      formInline: {
        account: '',
        password: ''
      },
      partList: [],
      info: {
        account: '',
        tel: '',
        carmodel: '',
        prices: '',
        frame: '',
        warranty: '',
        wick: ''
      },
      typeIndex: 0,
      typeSelectindex: 0,
      modelSelectindex: 0,
      models: {},
      type: {},
      part: {},
      part_num: 0,
      checkWarrantyStatus: true,
      checkWickStatus: true,
      model: {},
      time: this.format(new Date())
    }
  },
  computed: {
    ...mapState([
      'token',
      'name'
    ])
  },
  mounted () {
    if (this.token !== '') {
      this.init()
    }
  },
  methods: {
    ...mapMutations(['login', 'login_out']),
    async loginBtn () {
      if (this.formInline.account === '' || this.formInline.password === '') {
        this.$layer.msg('用户名密码不能为空!!')
        return
      }
      const data = {
        ...this.formInline
      }
      this.loading = true
      try {
        const res = await login(data)
        this.$layer.msg(res.msg)
        this.loading = false
        if (res.status === 1) {
          this.login(res.data)
          this.init()
        }
      } catch (err) {
        this.loading = false
      }
    },
    async checkWarranty () {
      if (!this.checkWarrantyStatus) {
        return false
      }
      this.$layer.msg('质保单号检测中...')
      const data = {
        value: this.info.warranty
      }
      const res = await checkWarrantyStatus(data)
      this.$layer.closeAll()
      if (res.status === 0) {
        this.$layer.msg(res.msg)
      }
    },
    async checkWick () {
      if (!this.checkWickStatus) {
        return false
      }
      this.$layer.msg('卷芯号检测中...')
      const data = {
        value: this.info.wick
      }
      const res = await checkWickStatus(data)
      this.$layer.closeAll()
      if (res.status === 0) {
        this.$layer.msg(res.msg)
        return false
      }

      this.typeIndex = this.type.findIndex(res1 => res1.value === res.data.type)
      this.typeSelectindex = this.typeIndex
      this.model = this.models[res.data.type]
      this.modelSelectindex = this.model.findIndex(res1 => res1.value === res.data.model)
    },
    format (str) {
      return moment(str).format('YYYY-MM-DD')
    },
    resetData () {
      this.formInline.account = ''
      this.formInline.password = ''
    },
    loginOut () {
      this.login_out()
    },
    async init () {
      try {
        const res = await getType()
        this.typeIndex = await 0
        this.type = await res.data.type
        this.models = await res.data.model
        this.model = await this.models[this.type[0].value]
        this.part = await res.data.part
        if (res.data.data.check_warranty === 0) {
          this.checkWarrantyStatus = false
        }
        if (res.data.data.check_wick === 0) {
          this.checkWickStatus = false
        }
      } catch (e) {
      }
    },
    async save () {
      if (this.info.account === '') {
        this.$layer.msg('请输入姓名')
        return false
      } else if (this.info.tel === '') {
        this.$layer.msg('请输入联系方式')
        return false
      } else if (this.info.frame === '') {
        this.$layer.msg('请输入车架号')
        return false
      } else if (this.info.warranty === '') {
        this.$layer.msg('请输入质保单号')
        return false
      } else if (this.info.wick === '') {
        this.$layer.msg('请输入质保单号')
        return false
      } else if (this.info.wick === '') {
        this.$layer.msg('请输入质保单号')
        return false
      } else if (this.partList.length === 0) {
        this.$layer.msg('请选择施工部位')
        return false
      }
      // 重新组合part
      var part = this.part[this.type[this.typeSelectindex].value]
      part.forEach((item, index) => {
        const partList = this.partList
        partList.forEach((item1) => {
          if (item.value === item1) {
            part[index].select = 1
          }
        })
      })
      const data = {
        info: this.info,
        part: part,
        type: this.type[this.typeIndex].value,
        model: this.model[this.modelSelectindex].value,
        time: this.time
      }
      var telphone = /^1[2|3|4|5|6|7|8|9]\d{9}$/
      if (!telphone.test(this.info.tel)) {
        this.$layer.msg('手机号不正确')
        return false
      }
      this.loading = true
      const res = await saveProtect(data)
      if (res.status === 1) {
        this.$layer.msg(res.msg)
        this.loading = false
        this.info = {
          account: '',
          tel: '',
          carmodel: '',
          prices: '',
          frame: '',
          warranty: '',
          wick: ''
        }
        this.partList = {}
        return false
      } else {
        this.$layer.msg(res.msg)
        this.loading = false
        return false
      }
    }
  },
  watch: {
    typeSelectindex (val) {
      this.model = this.models[this.type[val].value]
    },
    token () {
      if (this.token !== '') {
        this.init()
      } else {
        this.login_out()
      }
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  min-width: 1200px;
  height: 100vh;
  background: url("../../assets/beijing.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form_list {
  margin-right: 8%;
  margin-top: 20px;
  min-width: 372px;
  padding: 50px 0;
}

.search_form {
  width: 20%;
  background: url("../../assets/bg1.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

form {
  margin: 0 auto;
  width: 99%;
  max-width: 600px;
}

.search_form form .item {
  width: 90%;
  margin-bottom: 20px;
  padding-left: 50px;
  background: #fff;
}

.search_form form .item:nth-child(1) {
  background: url("../../assets/account.png") no-repeat left 10px center;
}

.search_form form .item:nth-child(2) {
  background: url("../../assets/password.png") no-repeat left 10px center;
}

/deep/ .ivu-input {
  display: block;
  padding: 0 10px;
  max-width: 360px;
  /*height: 42px;*/
  font-size: 13px;
  color: #666;
  border-radius: 8px;
  border: none;
  box-shadow: none;
}

.btns {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-box {
  width: 190px;
  height: 40px;
  border-radius: 20px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background: #339999;
  border: 1px solid #339999;
}

.zc_form {
  position: relative;
  max-width: 600px;
  width: 22%;
  padding: 50px 20px;
  background: url("../../assets/bg2.png") no-repeat;
  background-size: 100% 100%;
}

.cancel {
  position: absolute;
  right: -55px;
  top: 10px;
  font-size: 16px;
  color: #fff;
}

.zc_form form .item {
  min-height: 42px;
  /*margin-bottom: 10px;*/
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.zc_form form .last-item {
  margin-bottom: 20px;
  align-items: flex-start;
}

.zc_form form .item .label {
  min-width: 102px;
  padding-right: 15px;
  text-align: right;
  font-size: 14px;
  color: #fff;
}

.zc_form form .item .desc {
  font-size: 14px;
  color: #fff;
  padding: 0 10px;
  text-align: left;
}

/deep/ .ivu-checkbox-group {
  padding-left: 10px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.ivu-checkbox-group-item {
  margin-bottom: 5px;
  width: 30%;
  text-align: left;
}

.choice {
  font-size: 14px;
  color: #fff;
}

.result-box {
  margin-right: 10%;
  margin-top: 20px;
  width: 372px;
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
</style>
